/* App.css */
html, body, #root {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  text-align: center;
  width: 100%;
}
